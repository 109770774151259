import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-impressum',
    templateUrl: './impressum.component.html',
    styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

    public data = {
        taxIdentication: "DE325186466",
        taxNumber: "218/137/50659",
        taxNumberEnabled: false
    };

    constructor() { }

    ngOnInit() {
    }
}
