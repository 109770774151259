import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {CareerComponent} from './components/career/career.component';
import {JobComponent} from './components/job/job.component';
import {ImpressumComponent} from './components/impressum/impressum.component';
import {ProductComponent} from './components/product/product.component';
import {ContactComponent} from './components/contact/contact.component';
import {AboutUsComponent} from './components/about-us/about-us.component';
import {ServicesComponent} from './components/services/services.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'career', component: CareerComponent},
  {path: 'job/:id', component: JobComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'products', component: ProductComponent},
  {path: 'product/:name', component: ProductComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'services', component: ServicesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
