export interface Job {
  id: string;
  type: string;
  gender: string;
  description: string;
  startDate: string;
  languages?: string[];
}

export class JobHelper {

  public static getList(): Job[] {
    return [
      {
        id: "job-offer-001",
        type: "Vollzeit",
        gender: "(m/w)",
        description: "Webentwicklung Front-End",
        startDate: "April 2023"
      },
      {
        id: "job-offer-002",
        type: "Vollzeit",
        gender: "(m/w)",
        description: "Softwareentwicklung JEE / Spring Boot (Java)",
        startDate: "Juli 2023"
      },
      {
        id: "job-offer-005",
        type: "Werkstudent",
        gender: "(m/w)",
        description: "Webentwicklung Front-End",
        startDate: "November 2019"
      },
      {
        id: "job-offer-004",
        type: "Werkstudent",
        gender: "(m/w)",
        description: "Mobile Entwicklung Android / iOS",
        startDate: "Januar 2020"
      }
      /*
        {
            id: "job-offer-003",
            type: "Werkstudent",
            gender: "(m/w)",
            description: "Softwareentwicklung PHP",
            startDate: "Januar 2020"
        }
       */
    ];
  }

  public static getProgrammingLanguagesByJobId(jobId: string): string[] {
    const languages: any = {
      "job-offer-001": [
        "HTML5", "CSS3", "Bootstrap", "JavaScript / jQuery", "TypeScript", "Angular",
        "ReactJS", "VueJS", "JSON", "REST"
      ],
      "job-offer-002": [
        "Java", "Kotlin", "Spring", "JSON", "REST", "MongoDB", "PostgreSQL", "MariaDB"
      ],
      "job-offer-003": [
        "PHP", "Laravel", "JSON", "REST", "PostgreSQL", "MariaDB"
      ],
      "job-offer-004": [
        "Java", "Kotlin", "Swift", "SQLite", "XML"
      ],
      "job-offer-005": [
        "HTML5", "CSS3", "Bootstrap", "JavaScript / jQuery", "TypeScript", "Angular",
        "ReactJS", "VueJS", "JSON", "REST"
      ],
    };

    if (typeof languages[jobId] != "undefined") {
      return languages[jobId];
    }
    return [];
  }


}
