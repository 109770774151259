import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {Job, JobHelper} from 'src/app/models/job';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {

  public job: Job;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    const self = this;

    // watch navigation changes
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        self.init();
      }
    });

    self.init();
  }

  private init() {
    const self = this;
    const jobs: Job[] = JobHelper.getList();
    let jobId: string = null;
    let jobFound = false;

    // get job's id from url
    this.route.params.subscribe(params => {
      jobId = (typeof params.id != "undefined") ? params.id : null;
    });

    // iterate job and get target
    jobs.forEach(function (item: Job) {
      if (item.id == jobId) {
        self.job = item;

        // set job programming languages
        self.job.languages = JobHelper.getProgrammingLanguagesByJobId(jobId);

        // update status
        jobFound = true;
        return;
      }
    });

    // job could not be found
    if (!jobFound) {
      const job: Job = {
        id: null,
        type: '',
        gender: '(m/w)',
        description: 'Initiativ Bewerbung',
        startDate: '--'
      };

      // set job's languages
      job.languages = [];

      // set job
      this.job = job;
    }
  }

  public isValid(job: Job): boolean {
    return (job.id != null);
  }
}
