import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public data = {
    currentYear: new Date().getFullYear(),
    view: "home"
  };
  public exaliTitleText: string | null;

  public constructor(private router: Router) {
  }

  public ngOnInit() {
    const self = this;

    // watch router
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        self.data.view = self.router.url.substring(1).split("/")[0];

        // collapse menu
        document.getElementById('navbarCollapse').className = 'navbar';

        // scroll to top
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });

    this.exaliTitleText = 'Mehr Informationen zur IT-Haftpflicht von Sleek Systems UG'
      + ' (haftungsbeschränkt), Nürnberg';
  }

  public isViewActive(view: string): boolean {
    return (this.data.view == view);
  }

  public collapse(event: any): void {
    const trigger = (event.target.getAttribute('data-target'))
      ? event.target : event.target.parentNode;
    const targetElementId = trigger.getAttribute('data-target');
    const targetElement = document.getElementById(targetElementId);

    if (targetElement != null) {
      const isOpen = (targetElement.className.indexOf('open') >= 0);

      if (!isOpen) {
        targetElement.className += ' open';
      } else {
        targetElement.className = targetElement.className.replace(' open', '');
      }
    }
  }
}
