import {Component, OnInit} from '@angular/core';
import {Job, JobHelper} from '../../models/job';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

  public jobs: Job[];

  constructor() {
  }

  ngOnInit() {
    // get jobs list
    this.jobs = JobHelper.getList();
  }
}
